require("./lib/waypoints/waypoint");
require("./lib/waypoints/group");
require("./lib/waypoints/context");
require("./lib/waypoints/adapters/jquery");
require("./lib/waypoints/shortcuts/inview");
//require("./lib/ajax-search");

var project = require('../../settings');
var utils = require('./lib/even-height');

/* Event Handlers */
(function ($) {
    $("#menu-wrapper").mmenu(
        {
            "slidingSubmenus": false,
            "navbar": {
                "title": mmtitle,
            },
            "navbars": [
                {
                    "position": "bottom",
                    "content": [
                        "<div class='questions'>CONTACT US: " +
                        "<a href='tel:407-478-3759'>(407) 478-3759</a>" +
                        "</div>",
                        "searchfield",
                    ]
                }
            ],
            "extensions": [
                "pagedim-black",
                "theme-light",
                "position-right"
            ],
            "searchfield": {
                "search": false,
            },
            classNames: {
                fixedElements: {
                    fixed: "fix",
                    sticky: "stick"
                }
            }
        }, {
            "searchfield": {
                "form": {
                    "method": 'GET',
                    "action": homeurl
                },
                "input": {
                    "type": 'search',
                    "placeholder": 'Search Products',
                    "name": 's'
                },
            }
        }
    );

    var $header = $('.header-wrap');
    var $clone = $header.clone().addClass("clone").removeClass('main');
    $header.before($clone);

    $('.mm-searchfield__input input').append('<input type="hidden" name="post_type" value="product"/>');

    $(window).scroll(function () {
        var scroll = $(window).scrollTop();
        var $target = $header.height();

        if (scroll >= $target) {
            $clone.addClass('sticky');
            $header.addClass('u-hidden');
        } else if (scroll <= $target) {
            $clone.removeClass('sticky');
            $header.removeClass('u-hidden');
        }
    });

    $(window).on("load", function () {
        $('.builder-component-image .image-container').each(function (i, el) {
            var img = $(el).find('img')[0];
            var bg = img.currentSrc || img.src;
            $(el).css('background-image', 'url(' + bg + ')');
        });
        utils.equalHeight('.equal');
        $(window).trigger('resize').trigger('scroll');

        setInterval(function () {
            jQuery(window).trigger('resize').trigger('scroll');
        }, 1000);
    });

    function ScrollToHash(hash, child, offset, speed) {
        offset = offset || 0;
        hash = encodeURIComponent(hash.replace('#', ''));
        speed = speed || 800;
        var element = $('[data-scroll="' + hash + '"]');

        if (child && $(element).find(child).length > 0) {
            element = $(element).find(child);
        }

        if ($(element).length > 0) {
            if (!element.hasClass('recent-scroll')) {
                element.addClass('scroll-target').addClass('recent-scroll');
            }

            $('html, body').animate({
                scrollTop: (
                    element.offset().top - (200 + offset)
                )
            }, {
                complete: function () {
                    setTimeout(function () {
                        element.removeClass('scroll-target');
                    }, 500);

                    setTimeout(function () {
                        element.removeClass('recent-scroll');
                    }, 5000);
                }
            }, speed);
            return true;
        }

        return false;
    }

    var scroll_offset = -50;
    var scroll_speed = 1000;

    if (location.hash) {
        $(window).load(function () {
            ScrollToHash(location.hash, null, scroll_offset, scroll_speed);
        });
    }

    $(window).on('hashchange', function (e) {
        if (location.hash) {
            if (!ScrollToHash(location.hash, null, scroll_offset, scroll_speed)) {
                e.preventDefault();
            }
        }
    });

    $(document).ready(function () {
        //$('.team-members .block-wrap').show();
    });

    $('nav.primary .menu-top-level-item').on('mouseenter', function () {
        $(this).parents('.menu').find('.menu-top-level-item').removeClass('active');
        $(this).removeClass('fadeout').addClass('active');
    });

    $('nav.primary .menu-top-level-item').on('mouseleave', function () {
        var $this = $(this);

        setTimeout(function () {
            if (!$this.is(':hover')) {
                $this.addClass('fadeout');
            }
        }, 300);

        setTimeout(function () {
            if (!$this.is(':hover')) {
                $this.removeClass('active');
                $this.removeClass('fadeout');
            }
        }, 600);
    });

    $('.the-progressive-image').each(function (i, el) {
        $(this).data('waypoint', new Waypoint({
            element: $(el),
            offset: "200%",
            handler: function () {
                if (!$(el).hasClass('loaded')) {
                    var size = 0;

                    var sizes = [
                        JSON.parse($(el).attr('data-small')),
                        JSON.parse($(el).attr('data-med')),
                        JSON.parse($(el).attr('data-large')),
                        JSON.parse($(el).attr('data-full'))
                    ];

                    for (var i in sizes) {
                        if (sizes.hasOwnProperty(i)) {
                            size = i;

                            if (sizes[i].width >= window.innerWidth) {
                                break;
                            }
                        }
                    }

                    $('<img/>').attr('src', sizes[size].src).on('load', function () {
                        $(this).remove(); // prevent memory leaks
                        $(el).css('background-image', 'url(' + sizes[size].src + ')');

                        // $(el).parents('.gallery-image').featherlight(sizes[sizes.length-1].src);
                        $(el).addClass('loaded');
                    });
                }
            }
        }));
    });

    $('.single-accordion .acc-header, .builder-component-single-accordion .acc-header').on('click', function () {
        var $column = $(this).parent();
        $column.toggleClass('open');
        $column.find('.acc-content').slideToggle({
            duration: 250,
            progress: function () {
                $(window).trigger('resize');
            }
        });
    });

    $(document).ready(function () {
        $('.builder-layout-related-products-section .main-block').click(function() {
            var el = $(this);

            if( !el.hasClass('current') ) {
                // get the id of the section to show
                var hash = $(this).attr('href');
                var $match = hash.replace('#', '');

                window.location = hash;

                var $tab_id = el.attr('data-tab');

                $('.related-products-row.active').show(300, function() {
                    $(this).removeClass('active');
                });

                $('.main-block.current').removeClass('current');
                el.addClass('current');

                $("#" + $tab_id).addClass('active');

                if ($match !== $tab_id) {
                    $("#" + $tab_id).removeClass('active');
                }
            }
            return false;
        });

        if(window.location.hash != '') {
            var el = $(window.location.hash);
            if (el.length > 0) {
                el.click();
            }
        }

        $('.btn-top').on('click', function (event) {
            $('body, html').animate({
                scrollTop: 0
            }, 800);
            event.preventDefault();
        });

        var scrollDist = 300;

        $(window).scroll(function () {
            if ($(window).scrollTop() > scrollDist) {
                $('.btn-top').fadeIn('slow');
            } else {
                $('.btn-top').fadeOut('slow');
            }
        });

        $('.table').footable();

        $.fn.digits = function () {
            return this.each(function () {
                $(this).text($(this).text().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"));
            })
        }

        $('.lumens-comma').digits();

        setTimeout(function () {
            $('.prdctfltr_woocommerce_ordering').prepend('<div class="close-me">Close <span>X</span></div>');
        }, 500);

        setTimeout(function () {
            $('.prdctfltr_woocommerce_ordering .close-me').on('click', function () {
                $(this).parent().toggleClass('hide-me');
            });
        }, 1000);

        $('.prdctfltr_filter_title').append('<div class="side-filter-wrap"><div class="open-side-filter">Toggle Filter</div></div>');

        $("a.prdctfltr_woocommerce_filter").trigger("click");

        $('.open-side-filter').on('click', function () {
            $('.prdctfltr_woocommerce_ordering').toggleClass('hide-me');
        });

        $('.icons-slider').slick({
            slidesToShow: 5,
            slidesToScroll: 1,
            arrows: true,
            autoplay: true,
            responsive: [
                {
                    breakpoint: 1000,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 500,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });

        $('.product-cat-slider').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            autoplay: false
        });

        $('.slider-nav').slick({
            arrows: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            asNavFor: '.product-cat-slider',
            autoplay: false,
            focusOnSelect: true,
            responsive: [
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 530,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });

        $('.slider-nav').on('mouseenter', '.slick-slide', function (e) {
            var $currTarget = $(e.currentTarget),
                index = $currTarget.data('slick-index'),
                slickObj = $('.product-cat-slider').slick('getSlick');
            slickObj.slickGoTo(index);
        });
    });

    $(document).on('nfFormReady', function (e, layoutView) {
        $(".nf-form-layout form .recognition-wrap select").change(function () {
            if ($(this).val() == 'other') {
                $('.other-wrap').show();
            } else if ($(this).val() != 'other') {
                $('.other-wrap').hide();
            }
        });
    });

    // Not sure if necessary..
    function adjustIframes() {
        $('iframe').each(function () {
            var $this = $(this),
                proportion = $this.data('proportion'),
                w = $this.attr('width'),
                actual_w = $this.width();

            if (!proportion) {
                proportion = $this.attr('height') / w;
                $this.data('proportion', proportion);
            }

            if (actual_w != w) {
                $this.css('height', Math.round(actual_w * proportion) + 'px');
            }
        });
    }

    $(window).on('resize load', adjustIframes);
})(jQuery);